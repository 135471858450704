"use client";

import { NotificationBar } from "@litonarefin/components/NotificationBar";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";
import { isEmpty } from "lodash";
import { usePathname } from "next/navigation";

const Notification = ({ notifications }) => {
  const pathName = usePathname();

  const solidPathName = pathName.substring(1, pathName.length).split("/")?.[0];

  const { notification } = getNotificationData(notifications, solidPathName);

  return !isEmpty(notification) ? <NotificationBar notification={notification} /> : null;
};

export default Notification;

function getNotificationData(notifications, path) {
  const siteSource = getSourceSite();

  let fullSiteNotification, individualSiteNotification, notification;

  if (notifications?.notification_bar_settings?.full_site_notification?.is_open_notification) {
    fullSiteNotification = notifications?.notification_bar_settings?.full_site_notification;
  }

  individualSiteNotification =
    notifications?.notification_bar_settings?.individual_site_notification;

  if (!isEmpty(individualSiteNotification)) {
    let result;
    individualSiteNotification?.map((item) => {
      if (
        item?.product_page &&
        path ===
          removeLastChar(item?.product_page).replace(
            `${process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL}/${siteSource}_page/`,
            ""
          )
      ) {
        result = item;
      }
    });

    if (!isEmpty(result) && !!result?.is_open_notification) {
      notification = result;
    } else {
      notification = fullSiteNotification;
    }
  } else {
    notification = fullSiteNotification;
  }

  return { fullSiteNotification, individualSiteNotification, notification };
}

function removeLastChar(str = "") {
  let res = str?.charAt(str.length - 1);
  if (res === "/") {
    return str.slice(0, -1);
  } else {
    return str;
  }
}
