import { getIcon } from "@litonarefin/utils/icons";
import "./Popup.scss";
import { useEffect, useState, Fragment } from "react";
import { isEmpty } from "lodash";
import { BlockRenderer } from "../BlockRenderer";
import { cleanAndTransformBlocks } from "@litonarefin/utils/cleanAndTransformBlocks";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";
import { usePathname } from "next/navigation";
import { useExitIntent } from "use-exit-intent";

const Popup = ({ popupData, exitPopup }) => {
  const [popup, setPopup] = useState([]);
  const [individualPopup, setIndividualPopup] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isExitIntentPopup, setIsExitIntentPopup] = useState(false);
  const { unsubscribe, registerHandler } = useExitIntent();

  registerHandler({
    id: "openModal",
    handler: () => {
      if (isExitIntentPopup) {
        setIsOpenPopup(true);
      }
    },
  });

  const pathName = usePathname();
  const solidPathName = pathName.substring(1, pathName.length).split("/")?.[0];

  const siteSource = getSourceSite();

  useEffect(() => {
    let exitPagesJSON = localStorage.getItem(`${siteSource}-exit-popup`);
    let exitPages;
    if (exitPagesJSON) {
      exitPages = JSON.parse(exitPagesJSON);
    }

    if (!isEmpty(popupData)) {
      popupData?.map((item) => {
        let popupMeta = item?.popup_meta || {};

        if (
          solidPathName === popupMeta?.popup_page?.slug &&
          popupMeta?.is_popup &&
          popupMeta?.show_popup_on === "individual_product" &&
          !exitPages?.includes(popupMeta?.popup_page?.slug)
        ) {
          if (!popupMeta?.start_date && !popupMeta?.end_date) {
            setIsOpen(true);
            return setIndividualPopup(item);
          }

          if (
            popupMeta?.end_date &&
            popupMeta?.start_date &&
            new Date(popupMeta?.start_date).getTime().toString().slice(0, -3) <
              new Date().getTime().toString().slice(0, -3) &&
            new Date().getTime().toString().slice(0, -3) <
              new Date(popupMeta?.end_date).getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setIndividualPopup(item);
          }

          if (
            popupMeta?.start_date &&
            !popupMeta?.end_date &&
            new Date(popupMeta?.start_date).getTime().toString().slice(0, -3) <
              new Date().getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setIndividualPopup(item);
          }

          if (
            popupMeta?.end_date &&
            !popupMeta?.start_date &&
            new Date(popupMeta?.end_date).getTime().toString().slice(0, -3) >
              new Date().getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setIndividualPopup(item);
          }
        }

        // full site
        if (
          popupMeta?.show_popup_on === "full_site" &&
          popupMeta?.is_popup &&
          !exitPages?.includes("full-site")
        ) {
          if (!popupMeta?.start_date && !popupMeta?.end_date) {
            setIsOpen(true);
            return setPopup(item);
          }

          if (
            popupMeta?.end_date &&
            popupMeta?.start_date &&
            new Date(popupMeta?.start_date).getTime().toString().slice(0, -3) <
              new Date().getTime().toString().slice(0, -3) &&
            new Date().getTime().toString().slice(0, -3) <
              new Date(popupMeta?.end_date).getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setPopup(item);
          }

          if (
            popupMeta?.start_date &&
            !popupMeta?.end_date &&
            new Date(popupMeta?.start_date).getTime().toString().slice(0, -3) <
              new Date().getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setPopup(item);
          }

          if (
            popupMeta?.end_date &&
            !popupMeta?.start_date &&
            new Date(popupMeta?.end_date).getTime().toString().slice(0, -3) >
              new Date().getTime().toString().slice(0, -3)
          ) {
            setIsOpen(true);
            return setPopup(item);
          }
        }
      });
    }
  }, [solidPathName, popupData]);

  // Scroll Percentage
  const scrollPercentage = () => {
    let pos = document.documentElement.scrollTop;
    let calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrollVal = Math.round((pos * 100) / calcHeight);

    if (scrollVal >= 30 && !isExitIntentPopup) setIsOpenPopup(true);
  };

  useEffect(() => {
    window.onscroll = scrollPercentage;
  }, [isExitIntentPopup]);

  useEffect(() => {
    if (individualPopup?.popup_meta?.exit_intent || popup?.popup_meta?.exit_intent) {
      setIsExitIntentPopup(true);
    }
  }, [individualPopup, popup]);

  return (
    <Fragment>
      {isOpen && isOpenPopup ? (
        <div className="jlt-popup">
          <div className="jlt-popup-content">
            <span
              onClick={() => {
                exitPopup(
                  !isEmpty(individualPopup)
                    ? individualPopup?.popup_meta?.popup_page?.slug
                    : "full-site"
                );
                setIndividualPopup([]);
              }}
              className="jlt-popup-close"
            >
              {getIcon("close")}
            </span>
            <BlockRenderer
              blocks={
                !isEmpty(individualPopup)
                  ? cleanAndTransformBlocks(individualPopup?.blocks)
                  : cleanAndTransformBlocks(popup?.blocks)
              }
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default Popup;
