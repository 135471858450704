import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/components/CookieNotice/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/components/Fomo/ProductPromotion.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/components/Footer/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/notification/notification.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/libs/shared/popups/PopupLayout.jsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/libs/styles/index.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Nunito\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/toastr/build/toastr.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/jeweltheme.com/src/app/registry.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/jeweltheme.com/src/components/QueryClientProviderWrapper/QueryClientProviderWrapper.jsx");
